<template>
<div class="mb-4 body-program">
  <!-- <div class="head blue-grey lighten-5" /> -->
  <v-row class="pt-4 pb-2">
    <v-col cols="12">
      <v-row class="mx-0">
        <v-col
          cols="12"
          class="d-flex py-2 px-4"
          :class="{'justify-space-between': $vuetify.breakpoint.smAndUp, 'justify-center flex-column': $vuetify.breakpoint.xsOnly, }">
          <div
            class="d-flex"
            :class="{'justify-center flex-column': $vuetify.breakpoint.xsOnly, }"
          >
            <consultar-rango-fecha @consultar="consultarPrograma" />
            <div
              class="d-flex"
              :class="{'mt-2 ml-n2': $vuetify.breakpoint.xsOnly, }"
            >
              <btn-sort v-model="sortBy" />
              <v-btn
                color="icono"
                class="px-0"
                plain
                :ripple="false"
                small
                @click="sortDesc = !sortDesc"
              >
                <v-fade-transition>
                  <v-icon
                    size="22"
                    v-text="sortDesc ? 'mdi-sort-descending' : 'mdi-sort-ascending'"
                  />
                </v-fade-transition>
              </v-btn>
            </div>
          </div>

          <div :class="{'mt-4 d-flex': $vuetify.breakpoint.xsOnly}">
            <v-btn
              color="icono"
              class="mr-2"
              outlined
              small
              @click="legend = !legend"
            >
              <v-fade-transition>
                <v-icon
                  size="22"
                  :left="$vuetify.breakpoint.smAndUp"
                  v-text="legend ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'"
                />
              </v-fade-transition>
              <span v-if="$vuetify.breakpoint.smAndUp">Leyenda</span>
            </v-btn>
            <v-chip
              label
              color="icono"
              dark
              class="pa-3 flex-grow-1 justify-center"
            >
              {{ weeks.length > 0 ? weeks[filtro_semana].name : '' }}
              <v-btn
                small
                dark
                :disabled="filtro_semana <= 0"
                icon
                @click="formerWeek"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                small
                dark
                :disabled="filtro_semana >= (weeks.length -1)"
                icon
                @click="nextWeek"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-chip>
          </div>
        </v-col>
        <leyenda v-model="legend" />
        <v-col cols="12" class="pt-1"><v-divider class="elevation-1"/></v-col>
      </v-row>
    </v-col>
  </v-row>
  <promedio-visita :datos="dataVisita" :loading="loadProgVisita" />
  <loader-programa v-if="loadProgVisita" />
  <v-responsive
    v-else
    min-width="100%"
  >
    <div class="mb-2">
      <!-- <v-row justify="center" class="mx-0"> -->
        <v-sheet
          class="container-slide transparent"
        >
          <v-slide-group
            v-if="$vuetify.breakpoint.smAndUp"
            v-model="daysView"
            show-arrows="always"
            class="slide-programa"
          >
            <v-slide-item
              v-for="(dia, i) in itemsProg"
              :key="'diaVisitaItem'+i"
              :value="i"
            >
              <v-col
              :key="'diaVisita'+i"
              :class="{
                'card-programa': $vuetify.breakpoint.mdAndUp,
                'card-programa-sm': $vuetify.breakpoint.smOnly,
                'card-programa-xs': $vuetify.breakpoint.xsOnly,
              }"
              >
                <header-programa :dia="dia" @comment="addComment"/>
                <dia-visitas
                  v-if="momento(dia.fecha).isSameOrAfter(rango_prog_ini) && momento(dia.fecha).isSameOrBefore(rango_prog_fin)"
                  :dia="dia"
                  :sort="sortBy"
                  :sort-desc="sortDesc"
                />
              </v-col>
            </v-slide-item>
          </v-slide-group>

          <v-window
            v-if="$vuetify.breakpoint.xsOnly"
            v-model="daysView"
            class="slide-programa"
          >
            <v-window-item
              v-for="(dia, i) in itemsProg"
              :key="'diaVisitaItem'+i"
            >
              <v-col
                :key="'diaVisita'+i"
                class="card-programa-xs mx-auto"
              >
                <header-programa :dia="dia" @comment="addComment"/>
                <dia-visitas
                  v-if="momento(dia.fecha).isSameOrAfter(rango_prog_ini) && momento(dia.fecha).isSameOrBefore(rango_prog_fin)"
                  :dia="dia"
                  :sort="sortBy"
                  :sort-desc="sortDesc"
                />
              </v-col>
            </v-window-item>
          </v-window>
        </v-sheet>
      <!-- </v-row> -->
    </div>
  </v-responsive>
  <v-row
    v-if="$vuetify.breakpoint.xsOnly"
    class="ma-0">
    <v-col
      cols="12"
      class="d-flex justify-center align-center toolbar-mobile"
    >
      <v-btn-toggle
        rounded
        dense
        background-color="blue-grey lighten-1"
        dark
        active-class="active-action"
      >
        <v-btn
          @click="prevDay"
          class="px-4"
          :disabled="daysView === 0"
        >
          <v-icon left>mdi-arrow-left</v-icon>
          <span>Atras</span>
        </v-btn>
        <v-btn
          @click="nextDay"
          class="px-4"
        >
          <v-icon left>mdi-arrow-right</v-icon>
          <span>Siguiente</span>
        </v-btn>

      </v-btn-toggle>
    </v-col>
  </v-row>
  <modal-comentario v-model="showComment" :data="comment_ven" @update="getProgVisita" />
</div>
</template>
<script>
import moment from 'moment'
import es from 'moment/locale/es'
import { get } from 'vuex-pathify'
import {totalClientesNoPlanificado} from '@/util/helpers'
import {getComments} from '@/services/vendedores'
moment.locale('es', es);

export default {
  name: 'Programa',
   components:{
    BtnSort: () => import(
      /* webpackChunkName: "btn-sort" */
      './BtnSort.vue'
    ),
    Leyenda: () => import(
      /* webpackChunkName: "leyenda" */
      './Leyenda.vue'
    ),
    DiaVisitas: () => import(
      /* webpackChunkName: "dia-visitas" */
      './DiaVisitas.vue'
    ),
    ConsultarRangoFecha: () => import(
      /* webpackChunkName: "consultar-rango-fecha" */
      '@/widgets/ConsultarRangoFecha.vue'
    ),
    HeaderPrograma: () => import(
      /* webpackChunkName: "header-programa" */
      './HeaderPrograma.vue'
    ),
    LoaderPrograma: () => import(
      /* webpackChunkName: "loader-programa" */
      './LoaderPrograma.vue'
    ),
    PromedioVisita: () => import(
      /* webpackChunkName: "promedio-visita" */
      './PromedioVisita.vue'
    ),
    ModalComentario: () => import(
      /* webpackChunkName: "modal-comentario" */
      './ModalComentario.vue'
    ),
   },
  data: () => ({
    daysView: 0,
    filtro: '',
    filtro_semana: 0,
    legend: false,
    loadProgVisita: false,
    dataPrograma: [],
    rango_prog_ini: moment().startOf('week').day(1).format("YYYY-MM-DD"),
    rango_prog_fin: moment().format("YYYY-MM-DD"),
    dia_actual: moment().format("YYYY-MM-DD"),
    weeks: [],
    sortBy: 'hora_inicio',
    sortDesc: false,
    dataVisita: {
      PROMEDIO: 0,
      VISITAS: 0,
      VISITAS_NO_PLANIFICADAS: 0,
      TOTAL_VISITA: 0,
    },
    showComment: false,
    comment_ven: {},
    comments: [],
  }),
  created() {
    this.getProgVisita();
  },
  methods:{
    momento: moment,

    nextWeek() {
      if (this.filtro_semana + 1 <= this.weeks.length) this.filtro_semana += 1
    },
    formerWeek () {
      if (this.filtro_semana - 1 >= 0) this.filtro_semana -= 1
    },
    calcularSemanas() {
      if(moment(this.rango_prog_ini).day() === 0)
        this.rango_prog_ini = moment(this.rango_prog_ini).add(1, 'd').format("YYYY-MM-DD")

      this.filtro_semana = 0;
      this.weeks = [];
      this.weeks = [{
        name:  moment(this.rango_prog_ini).format("DD") + ' - ' + moment(this.rango_prog_ini).startOf('week').day(6).format("DD") + moment(this.rango_prog_ini).format(" MMM YYYY"),
        start:  moment(this.rango_prog_ini).format("YYYY-MM-DD"),
        end:  moment(this.rango_prog_ini).startOf('week').day(6).format("YYYY-MM-DD"),
      }];
      const totalSemanas = Math.trunc(moment(this.rango_prog_fin).diff(moment(this.rango_prog_ini), 'weeks', true));

      if(totalSemanas > 0) {
        for (let index = 1; index <= totalSemanas; index++) {
          this.weeks.push({
            name:  moment(this.rango_prog_ini).add(index, 'w').startOf('week').format("DD") + ' - ' + moment(this.rango_prog_ini).add(index, 'w').startOf('week').day(6).format("DD") +  moment(this.rango_prog_ini).add(index, 'w').startOf('week').format(" MMMM, YYYY"),
            start: moment(this.rango_prog_ini).add(index, 'w').startOf('week').format("YYYY-MM-DD"),
            end:   moment(this.rango_prog_ini).add(index, 'w').startOf('week').day(6).format("YYYY-MM-DD"),
          });
        }
      }
    },
    calcularPromedioVisita(){
      const JORNADA = Object.keys(this.dataPrograma).filter(item => {
        const [dia, mes, anio] = item.split('-')
        const diaSemana = moment(`${anio}-${mes}-${dia}`).day()

        if(diaSemana === 6 && this.dataPrograma[item]?.clientes?.length > 0)
          return true

        return diaSemana >=1 && diaSemana <= 5
      });
      const DIAS_JORNADA = JORNADA.length
      let TOTAL_VISITA = 0
      let VISITAS = 0
      let VISITAS_NO_PLANIFICADAS = 0

      for (const key in JORNADA){
        const { datos = null, clientes = null} = this.dataPrograma[JORNADA[key]]
        if(datos !== null){
          VISITAS += datos?.cv ?? 0
          VISITAS_NO_PLANIFICADAS += totalClientesNoPlanificado({clientes: clientes ?? []}) ?? 0
        }
      }
      TOTAL_VISITA = VISITAS + VISITAS_NO_PLANIFICADAS
      const PROMEDIO = Math.floor(TOTAL_VISITA / DIAS_JORNADA)
    return { PROMEDIO, VISITAS, VISITAS_NO_PLANIFICADAS, TOTAL_VISITA }
    },
    async getProgVisita() {
      this.loadProgVisita = true;
      try {
        const prog_visita = await this.$api.get(`vendedores/${this.idVen}/programavisita2?fecha_inicio=${moment(this.rango_prog_ini).format("YYYY-MM-DD")}&fecha_final=${moment(this.rango_prog_fin).format("YYYY-MM-DD")}`);
        this.comments = await getComments({
          co_ven: this.idVen,
          fecha_inicio: this.rango_prog_ini,
          fecha_final: this.rango_prog_fin,
        })
        this.dataPrograma = prog_visita.data.data;
        this.calcularSemanas();
        this.dataVisita = this.calcularPromedioVisita()
      } catch (error) {
         this.$root.$showAlert(
          'Lo siento, hubo un error al intentar obtener los datos del Programa de Visita.',
          'error',
        )
      } finally {
        this.loadProgVisita = false;
      }
    },
    consultarPrograma(fecha) {
      this.rango_prog_ini = fecha.ini;
      this.rango_prog_fin = fecha.fin;
      this.getProgVisita();
    },
    viewDay(dia){
      return moment(dia.fecha).isSameOrAfter(this.rango_prog_ini);
    },
    addComment(data){
      this.showComment = true;
      console.log('add', data)
      this.comment_ven = {
        co_ven: this.idVen,
        fecha: data.fecha,
        text: data?.comentario?.comentario ?? null
      }
    },
    nextDay(){
      const MAX_ITEMS = this.itemsProg.length ?? 0

      if(this.daysView === MAX_ITEMS){
        return
      }
      this.daysView++
    },
    prevDay(){
      const MAX_ITEMS = this.itemsProg.length ?? 0
      if(this.daysView === 0){
        return
      }
      this.daysView--
    },
  },
  computed:{
    idVen: get('route/params@cod'),
    semanas() {
      let weeks = [{
        name:  moment(this.rango_prog_ini).format("DD") + ' - ' + moment(this.rango_prog_ini).startOf('week').day(6).format("DD") + moment(this.rango_prog_ini).format(" MMM YYYY"),
        start:  moment(this.rango_prog_ini).format("YYYY-MM-DD"),
        end:  moment(this.rango_prog_ini).startOf('week').day(6).format("YYYY-MM-DD"),
      }];

      const totalSemanas = Math.trunc(moment(this.rango_prog_fin).diff(moment(this.rango_prog_ini), 'weeks', true));

      if(totalSemanas > 0) {
        for (let index = 1; index <= totalSemanas; index++) {
          weeks.push({
            name:  moment(this.rango_prog_ini).add(index, 'w').startOf('week').format("DD") + ' - ' + moment(this.rango_prog_ini).add(index, 'w').startOf('week').day(6).format("DD") +  moment(this.rango_prog_ini).add(index, 'w').startOf('week').format(" MMMM, YYYY"),
            start: moment(this.rango_prog_ini).add(index, 'w').startOf('week').format("YYYY-MM-DD"),
            end:   moment(this.rango_prog_ini).add(index, 'w').startOf('week').day(6).format("YYYY-MM-DD"),
          })

        }
      }
      return weeks;
    },
    itemsProg() {
      let datos = [];
      const fechas = Object.keys(this.dataPrograma);
      if(fechas.length > 0) {
        for (const key in fechas) {
          const format = fechas[key].split('-')
          datos.push({
            fecha: format[2]+'-'+format[1]+'-'+format[0],
            dia_semana: moment(format[2]+'-'+format[1]+'-'+format[0]).format("d"),
            clientes: this.dataPrograma[fechas[key]].clientes ? this.dataPrograma[fechas[key]].clientes : [],
            datos_dia: this.dataPrograma[fechas[key]].datos ? this.dataPrograma[fechas[key]].datos : null,
            comentario: this.comments.find(item => moment(item.fecha).format("DD-MM-YYYY") === fechas[key]) ?? null
          })
        }
        return datos.filter(data => moment(data.fecha).isBetween(moment(this.weeks[this.filtro_semana].start).startOf('week').day(1).format("YYYY-MM-DD"), this.weeks[this.filtro_semana].end, undefined, '[]'));
      }
      return [];
    },
    rango() {
      const f1 = moment('2021-09-01');
      const f2 = moment('2021-09-05');
      return f2.diff(f1, 'weeks', true).toFixed(1);
    },
    barData(){
      return [
        {
          name: 'Planidficadas',
          data: [this.dataVisita.VISITAS]
        }, {
          name: 'No Planificadas',
          data: [this.dataVisita.VISITAS_NO_PLANIFICADAS]
        }
      ]
    }
  }

}
</script>
<style lang="sass">
.titulo-visita
  background-color: #fafafa
  display: flex !important
  -webkit-box-align: center !important
  -ms-flex-align: center !important
  align-items: center !important
  padding: 5px 10px
  line-height: 2.5em
  border: solid
  border-width: 0 0 0 0.15rem
  border-color: #43b02a !important

.sm-responsive-programa
  overflow-x: auto
  overflow-y: hidden

.container-programa-sm
  width: 1500px

.container-slide
  width: 100%

.body-program
  background: #fdfdfd

.slide-programa
  background: none
  & .v-slide-group__next, .v-slide-group__prev
    -webkit-box-align: start
    -ms-flex-align: start
    align-items: flex-start !important
    min-width: 30px !important
    & .v-icon.v-icon
      position: fixed
      transform: translateY(0px)
      color: #003b49 !important
      font-size: 1.9rem
      background-color: #fff
      border-radius: 50%
      box-shadow: 0 3px 1px -2px rgba(0,0,0,.2),0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12)!important

.toolbar-mobile
  position: fixed
  bottom:0
  right:0
  left:0
  margin: 2% auto
  & .v-item-group .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg, .active-action
    background: #272727 !important

</style>
